import React from 'react';
import style from './tour.module.scss';
import DataList from '../../components/datalist/datalist';

class Tour extends React.Component {
  render() {

    return (
      <div className={style.tour}>
        <DataList 
          blog="paolospaccamonti-tour"
          client={this.props.client}
        />
      </div>
    );

  }

}

export default Tour;
