import React from 'react';
import style from './bio.module.scss'
import Loader from '../../components/loader/loader';

class Bio extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      showLoader: false
    };

    this.fetchPosts = this.fetchPosts.bind(this);
  }

  fetchPosts() {
    this.props.client.blogPosts('paolospaccamonti-bio')
    .then(resp => {
      this.setState({
        showLoader: false
      });
      if(resp.posts.length) {
        this.setState({
          bio: resp.posts[0].body
        });
      }else{
        return;
      }
    })
    .catch(function(err) {
      // oops
    });
  }
  

  componentDidMount() {
    this.fetchPosts();
  }

  render() {

    return (
      <div className={style.bio}>
        {!!this.state.bio ? 
          <div className={style.wrapper}>
            <div className={style.wrapBio} dangerouslySetInnerHTML={{__html: this.state.bio}}></div>
          </div> :
          <div className={style.wrapper}>
            <Loader />
          </div>
        }
      </div>
    );

  }

}

export default Bio;
