import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from '../src/components/header/header';
import Navbar from '../src/components/navbar/navbar';
import Homepage from '../src/pages/homepage/homepage';
import Bio from '../src/pages/bio/bio';
import Tour from '../src/pages/tour/tour';
import Reviews from '../src/pages/reviews/reviews';
import tumblr from 'tumblr.js';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class App extends Component {

  constructor(props) {
    super(props);

    this.client = tumblr.createClient({
      credentials: {
        consumer_key: 'nAUthqanE333fcB0XywX8gKCNYjcwD4eWsHvVjXAaVsILarHGm',
        consumer_secret: 'vxbBjocx3SnHZ3JzWTG5OCzDCGMe96c9GBWJyvMkWTxiEHUCRr',
        token: 'iUXUR9qXKd5kpP3bGuJo01FM6IDybSyuAhCPMkyu4JTDdKH4wr',
        token_secret: '9DqEq8Asgxo6Oid4xCbxPfUUzIU9dyirkeWK6LSuqLTFgzoURD'
      },
      returnPromises: true,
    });
  }

  componentDidMount() {
  }
  
  
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Header />
          <div className="wrapper">
            <Navbar />
            <Route exact path="/" render={()=><Homepage client={this.client} />} />
            <Route exact path="/bio" render={()=><Bio client={this.client} />} />
            <Route exact path="/tour" render={()=><Tour client={this.client} />} />
            <Route exact path="/reviews" render={()=><Reviews client={this.client} />} />
          </div>
        </div>
      
      </Router>
    );
  }
}

export default App;
