import React from 'react';
import style from './review.module.scss';

class Review extends React.Component {

  render() {

    return (
      <div className={style.review}>
        <div className={style.content} dangerouslySetInnerHTML={{__html: this.props.review.body}}></div>
        <span>{this.props.review.title}</span>
      </div>
    );
  }

}

export default Review;
