import React from 'react';
import style from './homepage.module.scss'
import DataList from '../../components/datalist/datalist';
class Homepage extends React.Component {

  render() {

    return (
      <div className={style.homepage}>
        <DataList 
          blog="paolospaccamonti"
          client={this.props.client}
        />
      </div>
    );

  }

}

export default Homepage;
