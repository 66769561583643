import React from 'react';
import style from './loader.module.scss';
import LoaderSvg from '../../assets/images/loader.svg';

class Loader extends React.Component {

  render() {

    return (
      <div className={style.loader}>
        <div className={style.loader}>
          <img src={LoaderSvg} alt="" />
        </div>
      </div>
    );

  }

}

export default Loader;
